'use client';
import Image from 'next/image';
import useLinks from '@/hooks/useLinks';
import Link from 'next/link';
import { Locale } from '../../../i18n-config';
import { useTranslation } from 'react-i18next';
import { useParams } from 'next/navigation';

type Props = {};

const SiteSocialNetworkComponent = (props: Props) => {
  const { Links } = useLinks();
  const params = useParams<{ lang?: string }>();
  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');
  return (
    <section
      id="footer-socialNetwork"
      className="gap-3  d-flex flex-column justify-content-center align-content-center align-items-center "
    >
      <div className="tw-w-full lg:tw-h-80 tw-h-full lg:tw-pt-10 tw-pt-5  lg:tw-pb-16 tw-pb-4 tw-bg-gray-50 tw-flex-col tw-justify-start tw-items-center tw-gap-24 tw-inline-flex">
        <div className="tw-w-full lg:tw-h-60 tw-h-full tw-px-8 tw-flex-col tw-justify-start tw-items-center tw-gap-10 tw-flex">
          <div className="tw-self-stretch lg:tw-h-60 tw-h-full tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-flex ">
            <div className="tw-self-stretch tw-justify-start tw-items-start  lg:tw-gap-[120px] tw-gap-14  tw-flex tw-flex-col lg:tw-flex-row">
              <div>
                <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-6 tw-inline-flex">
                  <Link href={Links.home.href} className="tw-justify-start tw-items-center tw-gap-1 tw-inline-flex">
                    <Image
                      className="tw-w-10 tw-h-10 tw-relative"
                      alt="LouiseBot | AI Chatbot for Business"
                      crossOrigin="anonymous"
                      src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
                      width={32}
                      height={32}
                      title="LouiseBot logo"
                    />
                    <div className="tw-text-blue-700 tw-text-lg tw-font-extrabold tw-font-['Lexend'] tw-leading-7">
                      LouiseBot
                    </div>
                  </Link>
                  <div className="tw-w-72 tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                    {t('home/SiteSocialNetwork/text-01')}
                  </div>
                </div>
              </div>
              <div className="tw-w-full tw-self-stretch tw-justify-start tw-items-start tw-gap-5 tw-flex lg:tw-flex-row tw-flex-wrap">
                <div className="tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
                  <div className="tw-text-gray-700 tw-text-lg tw-font-semibold tw-font-['Lexend'] tw-leading-7">
                    {t('SiteNavbar/l-13')}
                  </div>
                  <Link
                    href={Links.contactUs.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.contactUs.name}
                  </Link>
                  <Link
                    href={Links.pricing.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.pricing.name}
                  </Link>
                </div>
                <div className="tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
                  <div className="tw-text-gray-700 tw-text-lg tw-font-semibold tw-font-['Lexend'] tw-leading-7">
                    {t('SiteNavbar/l-12')}
                  </div>
                  <Link
                    href={Links.AIChatbot.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.AIChatbot.name}
                  </Link>
                  <Link
                    href={Links.ScenarioChatbot.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.ScenarioChatbot.name}
                  </Link>
                  <Link
                    href={Links.VideoBot.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.VideoBot.name}
                  </Link>
                  <Link
                    href={Links.HumanClone.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.HumanClone.name}
                  </Link>
                  <Link
                    href={Links.Roadmap.href}
                    target={Links.Roadmap.target}
                    className="tw-opacity-0 tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.Roadmap.name}
                  </Link>
                </div>
                <div className="tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
                  <div className="tw-text-gray-700 tw-text-lg tw-font-semibold tw-font-['Lexend'] tw-leading-7">
                    {t('SiteNavbar/l-11')}
                  </div>
                  <Link
                    href={Links.WhyVideoBot.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.WhyVideoBot.name}
                  </Link>
                  <Link
                    href={Links.TextVSVideo.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.TextVSVideo.name}
                  </Link>
                  <Link
                    href={Links.ChatbotIntegration.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.ChatbotIntegration.name}
                  </Link>
                  <Link
                    href={Links.Roadmap.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.Roadmap.name}
                  </Link>
                </div>
                <div className="tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
                  <div className="tw-text-gray-700 tw-text-lg tw-font-semibold tw-font-['Lexend'] tw-leading-7">
                    {t('SiteNavbar/l-14')}
                  </div>
                  <Link
                    href={Links.BecomeAPartner.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.BecomeAPartner.name}
                  </Link>
                  <Link
                    href={Links.BecomeAnAffiliate.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.BecomeAnAffiliate.name}
                  </Link>
                </div>
              </div>
            </div>
            <div className="tw-self-stretch tw-justify-between tw-items-center tw-flex lg:tw-flex-row tw-flex-col-reverse tw-gap-8 lg:tw-gap-0">
              <small className=" tw-text-gray-500 tw-text-xs tw-font-normal tw-font-['Lexend'] tw-leading-tight tw-w-full">
                © LouiseBot 2024 - {process.env.NEXT_PUBLIC_APP_VERSION as string}
              </small>
              <div className="tw-self-stretch tw-justify-between lg:tw-items-center tw-items-start tw-inline-flex tw-w-full">
                <div className="tw-justify-start tw-items-start lg:tw-gap-12 tw-gap-4 tw-flex lg:tw-flex-row tw-flex-col">
                  <Link
                    href={Links.termsOfService.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.termsOfService.name}
                  </Link>
                  <Link
                    href={Links.faq.href}
                    className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
                  >
                    {Links.faq.name}
                  </Link>
                </div>
                <div className="tw-h-10 tw-justify-end tw-items-center tw-gap-6 tw-flex">
                  <img
                    className="tw-w-10 tw-h-10 tw-relative"
                    alt="LouiseBot | AI Chatbot for Business"
                    crossOrigin="anonymous"
                    src="https://cdn.1cdn.app/application/LOUISEBOT/24120315525635_GDPR.png"
                    width={320}
                    height={320}
                    title="LouiseBot logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteSocialNetworkComponent;
